<template>
  <div class="tabs">
    <div class="topSearch">
        设备名称 <el-input class="selectBox" size="small" placeholder="请输入" v-model="pageParams.equipmentName"></el-input>
        所属站点 <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.stationId">
            <el-option
            v-for="item in stationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        设备类型 <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.equipmentType">
            <el-option
            v-for="item in equipmentTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="searchBtn">
            <el-button type="primary" size="small" @click="getList('reset')">查询</el-button>
            <el-button type="info" plain size="small" @click="reset()">重置</el-button>
        </div>
    </div>
    <div class="operateBtn">
        <div @click="changeStatusSuc()"><span :class="successStatus ? 'btn suc' : 'btn suc fill'"></span>正常({{successNum}})</div>
        <div @click="changeStatusWar()"><span :class="warnStatus ? 'btn war' : 'btn war fill'"></span>报警({{warnNum}})</div>
      </div>
    <template v-if="tableData.length > 0">
      
      <div class="tableBox">
        <div v-for="(item, index) in tableData" :key="index" class="contentB" :style="item.errorInfo == '无异常' ? '' : { borderColor: '#F45A5C' } ">
          <div class="headerB" :style="item.errorInfo == '无异常' ? '' : { backgroundColor: '#F45A5C' } ">
            <div class="headName textOver" :title="item.equipmentName">{{ item.equipmentName }}</div>
            <!-- 暂无信号强度字段 -->
            <div class="headSign">
                <img src="../../../assets/signal.png" alt="">
                {{item.signalStrength }}
            </div>
          </div>
          <div class="contentBox">
            <el-descriptions
              :column="1"
              :label-style="{ color: '#727E97'}"
              :content-style="{ color: '#26334E' }"
              style="display:block"
            >
              <el-descriptions-item label="所在位置" >
                <descriptions-tooltip
                  :value="
                    item.location ? item.location : '--'
                  "
                ></descriptions-tooltip>
              </el-descriptions-item>
              <el-descriptions-item label="设备型号">
                <descriptions-tooltip
                  :value="item.equipmentModel ? item.equipmentModel : '--'"
                ></descriptions-tooltip>
              </el-descriptions-item>
              <el-descriptions-item label="设备规格">
                <descriptions-tooltip
                  :value="item.equipmentSpecs ? item.equipmentSpecs : '--'"
                ></descriptions-tooltip>
              </el-descriptions-item>
              <el-descriptions-item label="异 常" :content-style="item.errorInfo == '无异常' ? '' : { color: '#F45A5C',width:'220px' }">
                <descriptions-tooltip
                  :value="item.errorInfo ? item.errorInfo : '--'"
                ></descriptions-tooltip>
              </el-descriptions-item>
              <el-descriptions-item v-for="(son,indexs) in item.monitorPointList" :key="indexs" :label="son.monitorPointName">
                <span :style="son.warningFlag == 0 ? {color:'#26334E'} : { color: '#F45A5C' } " > {{son.monitorPointValue}}{{son.monitorPointUnit}} </span>&nbsp;&nbsp;  
                <span :style="son.warningFlag == 0 ? {fontSize:'12px',color:'#727E97'} : { fontSize:'12px',color: '#F45A5C' } "> ( {{son.uploadTime}} ) </span>
              </el-descriptions-item>
              </el-descriptions>
          </div>
          <div class="containFooter">
            <div class="Footer" @click="dataEcharts(item)">
              <i class="el-icon-s-marketing"></i> 数据趋势
            </div>
            <div class="Footer" @click="dataList(item)">
              <i class="el-icon-time"></i> 历史数据
            </div>
            <div class="Footer" @click="showMap(item)">
              <i class="el-icon-location"></i> 地图定位
            </div>
          </div>
        </div>
      </div>
      <div v-if="tableData.length > 0">
        <el-pagination
          background
          :page-sizes="[30, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          class="TablePages"
          :total="total"
          :page-size="pageParams.size"
          :current-page="pageParams.current"
          @current-change="currentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </template>

    <div v-else>
      <el-empty style="padding-top:200px" description="暂无数据"></el-empty>
    </div>
    <DataEcharts ref="DataEcharts"></DataEcharts>
    <DataHistory ref="DataHistory"></DataHistory>
    <location ref="location"></location>
  </div>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
import DataEcharts from "./components/dataEcharts";
import DataHistory from "./components/dataHistory";
import DescriptionsTooltip from "./components/descritionsTooltip.vue";
import location from '../siteAdministration/components/location.vue'
export default {
  name: "realMonitorSta",
  components: { DataEcharts, DataHistory, DescriptionsTooltip, location },
  data() {
    return {
      pageParams:{
        pageTable:1,
        current:1,
        size: 100,
        equipmentName:"",
        equipmentType:"",
        stationId:""
      },
      tableData: [
        // {
        //     "equipmentId": 1,
        //     "equipmentModel": "1",
        //     "equipmentName": "2",
        //     "equipmentSpecs": "3",
        //     "errorInfo": "4",
        //     "location": "5",
        //     "point":"125.75586700373934,41.676337791607708",
        //     "monitorPointList": [
        //         {
        //             "dataType": "738",
        //             "monitorPointName": "738",
        //             "monitorPointUnit": "738",
        //             "monitorPointValue": "783",
        //             "uploadTime": "73",
        //             "warningFlag": 0
        //         },
        //         {
        //             "dataType": "haha",
        //             "monitorPointName": "777",
        //             "monitorPointUnit": "4",
        //             "monitorPointValue": "5",
        //             "uploadTime": "6",
        //             "warningFlag": 1
        //         }
        //     ]
        // },
        //     {
        //     "equipmentId": 2,
        //     "equipmentModel": "837",
        //     "equipmentName": "378",
        //     "equipmentSpecs": "378",
        //     "errorInfo": "无异常",
        //     "location": "3783",
        //     "point":"125.75586700373934,41.676337791607708",
        //     "monitorPointList": [
        //         {
        //             "dataType": "37387",
        //             "monitorPointName": "37",
        //             "monitorPointUnit": "3787",
        //             "monitorPointValue": "783",
        //             "uploadTime": "783",
        //             "warningFlag": 0
        //         }
        //     ]
        // }
      ],
      total:1,
      warnStatus:true,
      successStatus:true,
      warnNum:null,
      successNum:null,
      equipmentTypeList:[],
      stationList:[]
    };
  },
  mounted() {
    // 设备类型
    // scadaApi.getOptions({names:"equipmentType,stationType"}).then(res => {
    //     this.equipmentTypeList = res.data.equipmentType
    // })
    scadaApi.eqTypeOptions().then(res => {
      this.equipmentTypeList = res.data
    })
    // 获取场站
    scadaApi.getStationOptions({stationType:1}).then(res => {
        this.stationList = res.data
    })
    this.getList('reset')
  },
  methods: {
    reset(){
        this.pageParams.current = 1
        this.pageParams.size = 30
        this.pageParams.equipmentName = ""
        this.pageParams.equipmentType = ""
        this.pageParams.stationId = ""
        this.warnStatus = true
        this.successStatus = true
        this.warnNum = 0
        this.successNum = 0
        this.getList('reset')
    },
    getList(reset){
        if(reset){
            this.pageParams.current = 1
            this.pageParams.size = 30
            this.getStatusNum()
        }
        let obj = {
            ...this.pageParams
        }
        if(!this.warnStatus){
            obj.equipmentStatus = 0
        }
        if(!this.successStatus){
            obj.equipmentStatus = 1 
        }
        scadaApi.getRealTime(obj).then(res => {
            this.tableData = res.data.list
            this.total = res.data.total
        })
    },
    getStatusNum(){
        scadaApi.getStatistics(this.pageParams).then(res => {
            this.successNum = res.data.normalNum
            this.warnNum = res.data.warningNum
        })
    },
    changeStatusSuc(){
        if(!this.warnStatus && this.successStatus){

        }else{
            this.successStatus = !this.successStatus
            this.getList('reset')
        }
    },
    changeStatusWar(){
        if(!this.successStatus && this.warnStatus){

        }else{
            this.warnStatus = !this.warnStatus
            this.getList('reset')
        }
    },
    currentChange(e){
        this.pageParams.current = e
        this.getList()
    },
    handleSizeChange(e){
        this.pageParams.size = e
        this.getList()
    },
    showMap(e){
      if(e.point){
        // 'POINT(118.207792 39.623391)'
        // let str = (e.point).slice(6,-1)
        // let arr = str.split(' ')
        // this.$refs.mapLocus.open(arr)
        this.$refs.location.open({},e.point)
      }else{
        this.$message.warning('该场站未标点')
      }
    },
    dataList(e){
        this.$refs.DataHistory.openData(e.equipmentId)
    },
    dataEcharts(e){
        this.$refs.DataEcharts.openData(e.equipmentId)
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-descriptions__body .el-descriptions__table {
  white-space: nowrap;
}
::v-deep.el-descriptions-item__content {
  color: #010101;
  font-size: 14px;
}
::v-deep.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
  padding-bottom: 4px;
  .el-descriptions-item__container{
    display: block !important;
  }
}
.tabs {
  margin: 10px;
  background-color: #fff;
  height: calc(100vh - 173px);
  .topSearch{
        color: #0F0F0F;
        border-bottom: 1px solid #E3E3E3;
        padding: 16px 10px;
        .selectBox{
            width: 200px;
            margin: 0 30px 0 0;
        }
        .searchBtn{
            display: inline-block;
        }
    }
}
.operateBtn{
    padding: 16px 20px;
    display: flex;
    div{
        margin-right: 20px;
        color: #0B1225;
        font-size: 14px;
        cursor: pointer;
        span{
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 2px;
            vertical-align: middle;
            margin-right: 4px;
        }
        .suc{
            background: #57C290;
        }
        .war{
            background: #F45A5C;
        }
        .fill{
            filter: grayscale(100%);
        }
    }
}
.textOver {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tableBox{
    padding: 0 16px;
    height: calc(100vh - 358px);
    overflow-y: auto;
}
.TablePages{
    margin: 16px 20px;
    padding-left: 0;
}
.tableBox::after {
  display: block;
  content: '';
  clear: both;
}
.tabsBar {
  width: 100%;
  height: 44px;
  background: #f6f6f6;
  display: flex;
  padding-left: 20px;
  .activeBtn {
    width: 120px;
    height: 37px;
    background: #ffffff;
    text-align: center;
    line-height: 37px;
    margin-top: 7px;
    position: relative;
    .point {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 14px;
      left: 30px;
    }
  }
  .btn {
    width: 120px;
    height: 37px;
    background: #f6f6f6;
    text-align: center;
    line-height: 37px;
    margin-top: 7px;
    cursor: pointer;
    position: relative;
    .point {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 14px;
      left: 30px;
    }
  }
}
.contentB:nth-of-type(5n){
    margin-right: 0;
}
.contentB {
  height: 310px;
  width: 18.6%;
  float: left;
  position: relative;
  border: 1px solid #57C290;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
  .headerB {
    height: 44px;
    background-color: #57C290;
    line-height: 44px;
    color: #ffffff;
    padding: 0 16px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .headName{
        width: calc(100% - 50px);
        font-weight: bold;
    }
    .headSign{
        display: flex;
        align-items: center;
        font-size: 12px;
        img{
            margin-right: 6px;
        }
    }
  }
  .contentBox {
    padding: 12px 8px 12px 16px;
    position: relative;
    height: calc(100% - 110px);
    overflow: auto;
    .continer1 {
      font-size: 15px;
      font-weight: 600;
      color: #010101;
      width: calc(100% - 25px);
      height: 25px;
    }
    .singleWifi {
      width: 25px;
      height: 40px;
      position: absolute;
      right: 20px;
      top: 20px;
      text-align: center;
      color: #06bb13;
      font-size: 12px;
      font-weight: 600;
    }
    .continer2 {
      font-size: 14px;
      font-weight: 400;
      color: #7f7f7f;
      margin-top: 6px;
      padding-bottom: 15px;
      border-bottom: 1px solid #dadada;
    }
  }
  .containFooter {
    height: 42px;
    background: #F6F6F6;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 5px;
    display: flex;
    color: #1082ff;

    .Footer {
      flex: 1;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>